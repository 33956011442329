var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"persistent":"","max-width":"400px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"loading":_vm.loading}},[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(!_vm.form.id ? 'Cadastro' : 'Edição')+" de dispositivo")])]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"5"}},[_c('v-text-field',{attrs:{"label":"ID","color":"white","hide-details":"auto","disabled":"","outlined":"","persistent-placeholder":"","dense":""},model:{value:(_vm.form.id),callback:function ($$v) {_vm.$set(_vm.form, "id", $$v)},expression:"form.id"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Nome","color":"white","hide-details":"auto","outlined":"","persistent-placeholder":"","dense":""},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":[
                  { text: 'Entrada', value: 'ENTRANCY' },
                  { text: 'Saída', value: 'EXIT' },
                  { text: 'Merenda', value: 'LUNCH' },
                ],"label":"Tipo de acesso","color":"white","hide-details":"auto","outlined":"","persistent-placeholder":"","dense":""},model:{value:(_vm.form.accessType),callback:function ($$v) {_vm.$set(_vm.form, "accessType", $$v)},expression:"form.accessType"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":[
                  { text: 'Intelbras A702', value: 'INTELBRAS_A702' },
                  { text: 'Intelbras A702-T', value: 'INTELBRAS_A702' },
                ],"label":"Modelo","color":"white","hide-details":"auto","outlined":"","persistent-placeholder":"","dense":""},model:{value:(_vm.form.model),callback:function ($$v) {_vm.$set(_vm.form, "model", $$v)},expression:"form.model"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Host","color":"white","hide-details":"auto","outlined":"","persistent-placeholder":"","dense":""},model:{value:(_vm.form.host),callback:function ($$v) {_vm.$set(_vm.form, "host", $$v)},expression:"form.host"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":"Usuário","color":"white","hide-details":"auto","outlined":"","persistent-placeholder":"","dense":""},model:{value:(_vm.form.user),callback:function ($$v) {_vm.$set(_vm.form, "user", $$v)},expression:"form.user"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":"Senha","color":"white","hide-details":"auto","outlined":"","persistent-placeholder":"","dense":""},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"info","outlined":"","loading":_vm.loadingTestingConnection},on:{"click":_vm.testConnection}},[_vm._v("Testar conexão")]),_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"color":"success","loading":_vm.loadingSave},on:{"click":_vm.save}},[_vm._v(" Salvar ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }