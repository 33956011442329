<script>
import DevicesService from '@/service/devices.service';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import cloneDeep from 'lodash/cloneDeep';

dayjs.extend(utc);
dayjs.extend(timezone);

export default {
  name: 'modal-register-device',

  data() {
    return {
      dialog: false,
      loading: false,
      loadingSave: false,
      loadingTestingConnection: false,
      form: {},
    };
  },

  created() {
    this.$on('create', this.create);
    this.$on('edit', this.edit);
  },

  methods: {
    create() {
      this.form = {};
      this.dialog = true;
    },

    edit(id) {
      this.fetch(id);
      this.dialog = true;
    },

    async fetch(id) {
      this.loading = true;

      try {
        const customer = await DevicesService.get(id);
        this.form = customer;
      } catch (e) {
        console.log(e);
      }

      this.loading = false;
    },

    async save() {
      this.loadingSave = true;
      try {
        const form = cloneDeep(this.form);
        if (form.id) {
          const id = this.form.id;
          delete form.id;
          await DevicesService.update(id, form);
        } else {
          await DevicesService.create(form);
        }
        this.dialog = false;
        this.$swal.fire({
          icon: 'success',
          title: 'Sucesso!',
          text: 'Salvo com sucesso!',
          timer: 2000,
          showConfirmButton: false,
        });
        this.$emit('success');
      } catch (e) {
        console.error(e);
      }
      this.loadingSave = false;
    },

    async testConnection() {
      this.loadingTestingConnection = true;
      try {
        const result = await DevicesService.softwareVersion(this.form);
        if (result?.success) {
          this.$swal.fire({
            icon: 'success',
            title: 'Conetado com sucesso!',
            text: `Versão do firmware: ${result?.data}`,
            showConfirmButton: true,
          });
        } else {
          this.$swal.fire({
            icon: 'error',
            title: 'Erro!',
            text: result?.message || 'Não foi possível estabelecer uma conexão.',
            showConfirmButton: true,
          });
        }
      } catch (e) {
        console.error(e);
      }
      this.loadingTestingConnection = false;
    },
  },
};
</script>

<template>
  <v-dialog v-model="dialog" persistent max-width="400px">
    <v-card :loading="loading">
      <v-card-title>
        <span class="text-h5">{{ !form.id ? 'Cadastro' : 'Edição' }} de dispositivo</span>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="12">
            <v-row>
              <v-col cols="12" sm="5">
                <v-text-field
                  v-model="form.id"
                  label="ID"
                  color="white"
                  hide-details="auto"
                  disabled
                  outlined
                  persistent-placeholder
                  dense
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="form.name"
                  label="Nome"
                  color="white"
                  hide-details="auto"
                  outlined
                  persistent-placeholder
                  dense
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-select
                  v-model="form.accessType"
                  :items="[
                    { text: 'Entrada', value: 'ENTRANCY' },
                    { text: 'Saída', value: 'EXIT' },
                    { text: 'Merenda', value: 'LUNCH' },
                  ]"
                  label="Tipo de acesso"
                  color="white"
                  hide-details="auto"
                  outlined
                  persistent-placeholder
                  dense
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-select
                  v-model="form.model"
                  :items="[
                    { text: 'Intelbras A702', value: 'INTELBRAS_A702' },
                    { text: 'Intelbras A702-T', value: 'INTELBRAS_A702' },
                  ]"
                  label="Modelo"
                  color="white"
                  hide-details="auto"
                  outlined
                  persistent-placeholder
                  dense
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="form.host"
                  label="Host"
                  color="white"
                  hide-details="auto"
                  outlined
                  persistent-placeholder
                  dense
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="form.user"
                  label="Usuário"
                  color="white"
                  hide-details="auto"
                  outlined
                  persistent-placeholder
                  dense
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="form.password"
                  label="Senha"
                  color="white"
                  hide-details="auto"
                  outlined
                  persistent-placeholder
                  dense
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn color="info" outlined :loading="loadingTestingConnection" @click="testConnection">Testar conexão</v-btn>
        <v-spacer></v-spacer>
        <v-btn text @click="dialog = false"> Cancelar </v-btn>
        <v-btn color="success" :loading="loadingSave" @click="save"> Salvar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
